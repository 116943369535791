export const api_endpoint = "https://develop.sabia-api.dyn1.payrollselect.nl";
export const bugsnag_key = "";
export const min_start_date = new Date("01-01-2020");
export const release_stage = "development";
export const intercom_app_id = "dzmw9ffi";
export const google_analytics_id = "";
export const picNicCompanyId = "808c7fa6-8b67-4655-bb84-2ac5bc04efde";
export const easyPayrollBaseUrl = "https://test.easypayroll.nl";
export const easyPayrollApiUrl = "https://test.easypayroll.nl/wp-json/dij";
export const featureSearchAddressBarEnabled = false;
export const maintenance_window_end = null;

export const appThemes = {
  "dbv-picnic-test.payrollselect.nl": "picnic",
  "dbv-vattenfall-test.payrollselect.nl": "vattenfall",
  "dbv-easyworx-test.payrollselect.nl": "easyworx",
  "dbv-test.payrollselect.nl": "payroll",
  "app-test.easyjobs.nl": "easyjobs",
  "dbv-easypayroll-test.payrollselect.nl": "easypayroll",
  "dbv-gorillas-test.payrollselect.nl": "gorillas"
};
